(function() {
  var deferred, langSelector;

  window.getParameterByName = function(name) {
    var regex, results;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    results = regex.exec(location.search);
    if (results == null) {
      return '';
    } else {
      return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
  };

  window.pageLang = document.getElementsByTagName('html')[0].getAttribute('lang');

  langSelector = document.getElementById('langsList');

  if (langSelector) {
    window.selectedLang = langSelector.options[langSelector.selectedIndex].value;
    window.pageLang = langSelector.value;
  }

  window.onLangChange = function() {
    var pageLinkInSelectedLang, selectedLang;
    selectedLang = langSelector.value || langSelector.options[langSelector.selectedIndex].value;
    document.cookie = ("lang=" + selectedLang + ";path=/;max-age=") + 1 * 365 * 24 * 60 * 60;
    pageLinkInSelectedLang = document.getElementById('lang-link-' + selectedLang).getAttribute('href') + location.hash;
    return location.assign(pageLinkInSelectedLang);
  };

  window.getCookie = function(cname) {
    var c, ca, i, name;
    name = cname + '=';
    ca = document.cookie.split(';');
    i = 0;
    while (i < ca.length) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
      i++;
    }
    return '';
  };

  window.roundPrice = function(number) {
    return Number(number).toFixed(2) * 100 / 100;
  };

  window.getSession = function() {
    var err, sessionStr;
    if (window.localStorage == null) {
      return;
    }
    if (!(sessionStr = localStorage.session)) {
      return;
    }
    try {
      return JSON.parse(sessionStr);
    } catch (_error) {
      err = _error;
      delete localStorage.session;
      return void 0;
    }
  };

  deferred = function() {
    var promise, reject, resolve;
    resolve = reject = null;
    promise = new Promise(function() {
      return resolve = arguments[0], reject = arguments[1], arguments;
    });
    return {
      promise: promise,
      resolve: resolve,
      reject: reject
    };
  };

  window.Pomotodo = {
    getSession: getSession,
    deferred: deferred(),
    redirectTo: function(href) {
      if (href) {
        return location.href = href;
      }
    },
    request: function(method, url, data, userOpts) {
      var defer, options;
      defer = deferred();
      url = "https://api-ng.pomotodo.com/" + url;
      options = $.extend({
        method: method,
        url: url,
        dataType: "json",
        contentType: 'application/json',
        processData: false,
        data: JSON.stringify(data)
      }, userOpts);
      $.ajax(options).done(function(data, textStatus, jqXHR) {
        return defer.resolve(data);
      }).fail(function(jqXHR) {
        return defer.reject(jqXHR);
      });
      return defer.promise;
    }
  };

}).call(this);
